<template>
  <div class="demo col-md-12 mx-auto border p-4">
    <editor
      :id="id"
      ref="editor"
      :config="config"
      :initialized="onInitialized"
      v-if="isEdit == false"
    />
    <editor
      :id="id"
      ref="editor"
      :config="config"
      :initialized="onInitialized"
      v-if="isEdit == true"
    />
    <b-modal ref="modalBlockRule" id="modalBlockRule" hide-header>
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >基本設定(どの変数を持つユーザーのみ表示させるか?を設定)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_1"
              tag-placeholder="購入付与スラグを追加してください"
              placeholder="購入付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
              @input="selectSlagType1"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >and1(基本設定とand1両方の変数を持つユーザーだけに表示)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
              :disabled="isDisableType2"
              @input="selectSlagType2"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >and2(基本設定 + and1+2の3点の変数を持つユーザーだけに表示)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_3"
              tag-placeholder="投票付与スラグを追加してください"
              placeholder="投票付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
              :disabled="isDisableType3"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row mb-3 row-input pl-3">
        <label class="col-sm-12 font-weight-bold pl-0"
          >非表示(以下の変数を持つユーザーは非表示)</label
        >
        <div
          class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
        >
          <div class="custom-select-2 w-100 custome-multi-slag">
            <multiselect
              v-model="slag_type_4"
              tag-placeholder="投票付与スラグを追加してください"
              placeholder="投票付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
              :disabled="isDisableType4"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="">
          <b-button
            v-on:click.prevent="getSlagBlock()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
            >アップデート
          </b-button>
          <b-button
            variant="secondary"
            @click="closeModalSetSlag()"
            class="mx-2 back-btn"
            >戻る</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modalSettingVideo"
      ref="modalSettingVideo"
      size="lg"
      title="動画連動HTML"
    >
      <b-row class="my-2 align-items-center">
        <b-col md="4" lg="3">
          <label class="my-auto">動画のID</label>
        </b-col>
        <b-col md="8" lg="9">
          <label id="idVideoEmbed" class="my-auto">{{ idVideoEmbed }}</label>
        </b-col>
      </b-row>
      <b-row class="my-2 align-items-center">
        <b-col md="4" lg="3">
          <label class="my-auto">表示時間 (開始)</label>
        </b-col>
        <b-col md="8" lg="7">
          <b-row>
            <b-col md="6" class="pr-md-1">
              <b-form-input
                id="minuteStart"
                v-model="minuteStart"
                :state="stateMinuteStart"
                type="number"
                placeholder="分"
                class="form-control"
                @input="onChangeMinuteStart"
              />
            </b-col>
            <b-col md="6" class="pl-md-1">
              <b-form-input
                id="secondStart"
                v-model="secondStart"
                :state="stateSecondStart"
                type="number"
                placeholder="秒"
                class="form-control"
                @input="onChangeSecondStart"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="my-2 align-items-center">
        <b-col md="4" lg="3">
          <label class="my-auto">表示時間 (終了)</label>
        </b-col>
        <b-col md="8" lg="7">
          <b-row>
            <b-col md="6" class="pr-md-1">
              <b-form-input
                id="minuteEnd"
                v-model="minuteEnd"
                :state="stateMinuteEnd"
                type="number"
                placeholder="分"
                class="form-control"
                @input="onChangeMinuteEnd"
              />
            </b-col>
            <b-col md="6" class="pl-md-1">
              <b-form-input
                id="secondEnd"
                v-model="secondEnd"
                :state="stateSecondEnd"
                type="number"
                placeholder="秒"
                class="form-control"
                @input="onChangeSecondEnd"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="my-2 align-items-center">
        <b-col md="4" lg="3">
          <label class="my-auto">終了後に表示する</label>
        </b-col>
        <b-col md="8" lg="9">
          <b-form-textarea
            id="textConfig"
            v-model="textConfig"
            rows="3"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row class="my-2 align-items-center">
        <b-col md="4" lg="3">
          <label class="my-auto">HTML内容</label>
        </b-col>
        <b-col md="8" lg="9">
          <b-form-textarea
            id="textEnd"
            v-model="textEnd"
            rows="3"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <template #modal-footer="{}">
        <div class="">
          <b-button
            id="createModalSettingvideo"
            v-on:click.prevent="createModalSettingvideo"
            :disabled="disabledSaveSetiingVideo"
            variant="info"
            class="mr-4"
            >アップデート
          </b-button>
          <b-button
            variant="secondary"
            @click="closeModalSettingvideo"
            class="mx-2 back-btn"
            >戻る</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { eventBus } from "../../main";
import { mapActions, mapGetters } from "vuex";

import Header from "@editorjs/header";
import List from "../../assets/js/editor_list";
import CodeTool from "@editorjs/code";
import Paragraph from "../../assets/js/editorjs_paragraphAlignment";
import Embed from "../../assets/js/editorjs_embed";
import Table from "@editorjs/table";
import Checklist from "@editorjs/checklist";
// import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import RawTool from "@editorjs/raw";
import Quote from "@editorjs/quote";
// import InlineCode from "@editorjs/inline-code";
import InlineCode from "../../assets/js/editor_inlineCode";
import Delimiter from "@editorjs/delimiter";
import AttachesPDFTool from "../../assets/js/editorjs_attaches_pdf";
import AttachesTool from "../../assets/js/editorjs_attaches";
import BoxStyle from "../../assets/js/editorjs_boxStyles";
import ImageTool from "../../assets/js/editorjs_images";
import MarkerTool from "../../assets/js/editorjs_markers";
import LineHeight from "../../assets/js/editorjs_lineHeightToolbar";
import HeaderStyle from "../../assets/js/editorjs_headerStyles";
import FontSize from "../../assets/js/editorjs_font_size";
import anyButton from "../../assets/js/editorjs_buttonStyles";
import DragDrop from "editorjs-drag-drop";
import AlignmentTuneTool from "../../assets/js/editorjs_blockTuneCustom";
import ToggleBlock from "../../assets/js/editorjs_blocks";
import TableOfContents from "../../assets/js/editorjs_tableOfContents";
import { Constants } from "../../utils/constants.js";
import ColorPlugin from "editorjs-text-color-plugin";
import ToggleTool from "../../assets/js/editorjs_toggle_tool";
import Hyperlink from "../../assets/js/editorjs_links";

export default {
  name: "EditerVue",
  props: ["dataEditor", "isEdit", "id"],
  data() {
    return {
      // eslint-disable-next-line no-undef
      idShop: this.$route.params.shopId,
      config: null,
      checkNull: null,
      listSlagOptions: [],
      slag_type_1: [],
      slag_type_2: [],
      slag_type_3: [],
      slag_type_4: [],
      isLoading: false,
      isDisableType2: true,
      isDisableType3: true,
      isDisableType4: false,
      blockItemId: null,
      dataSlagBlock: [],
      getDataSlagBlock: [],
      idVideoEmbed: "",
      minuteStart: "",
      secondStart: "",
      minuteEnd: "",
      secondEnd: "",
      textConfig: "",
      textEnd: "",
      stateMinuteStart: null,
      stateSecondStart: null,
      stateMinuteEnd: null,
      stateSecondEnd: null,
      dataSetingVideo: [],
      disabledSaveSetiingVideo: false,
    };
  },
  created() {
    this.configEditor();
    if (this.isEdit || this.isEdit == undefined) {
      this.config.data = this.dataEditor ? JSON.parse(this.dataEditor) : "";
      // this.isEdit = true;
    }
    if (
      localStorage.getItem(Constants.PAGE_PREVIEW) != null ||
      localStorage.getItem(Constants.REVIEW_PAGE) != null ||
      this.$route.name.includes("editFixedPage")
    ) {
      this.config.data = this.dataEditor ? JSON.parse(this.dataEditor) : "";
      // this.isEdit = true;
    }
    if (!this.$route.fullPath.includes("fixed-page")) {
      const request = {
        // shop_id: this.$route.params.shopId,
        shop_id: localStorage.getItem(Constants.SHOP_ID),
      };
      this.getALLlistSlag(request);
    }

    // css color text plugin
    window.addEventListener("click", function () {
      var layout = document.querySelector("xy-color-picker");
      if (layout) {
        var shR = layout.shadowRoot;
        var layoutBox = shR.querySelector(".color-sign");
        layoutBox.style.maxWidth = "unset";
        var textA = shR.querySelector(".color-fire-btn");
        textA.style.display = "none";
        var element = shR.querySelector("#color-btn");
        element.classList.remove("color-btn");
        element.textContent = "A";
        element.style.fontWeight = "bold";
        element.style.background = "none";
        element.style.color = "#FF1300";
        element.style.fontSize = "16px";
        element.style.textShadow = "2px 0 0 #cab9b9";
      }
    });
  },
  mounted() {
    eventBus.$on("getFormEditor", () => {
      this.submitEditor();
    });
    // this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
    //   if (modalId === "modalSettingVideo") {

    //   }
    // });
  },
  watch: {
    dataEditor() {
      if (typeof this.dataEditor != "object") {
        this.config.data = JSON.parse(this.dataEditor);
      } else {
        this.config.data = this.dataEditor;
      }
      if (this.$route.fullPath.includes("fixed-page")) {
        this.isEdit = true;
      }
      this.config.data.blocks.map((item) => {
        if (item.slag) {
          const dataPush = {
            id: item.id,
            slag_id_1:
              item.slag.slag_id_1 === undefined ? [] : item.slag.slag_id_1,
            slag_id_2:
              item.slag.slag_id_2 === undefined ? [] : item.slag.slag_id_2,
            slag_id_3:
              item.slag.slag_id_3 === undefined ? [] : item.slag.slag_id_3,
            slag_id_4:
              item.slag.slag_id_4 === undefined ? [] : item.slag.slag_id_4,
          };
          this.getDataSlagBlock.push(dataPush);
        }
      });
      this.config.data.blocks.map((item) => {
        if (item.type === "embed") {
          let dataInputSeting = {
            idBlock: item.id,
            minuteStart:
              item.data.setting.minuteStart === null
                ? ""
                : item.data.setting.minuteStart,
            secondStart:
              item.data.setting.secondStart === null
                ? ""
                : item.data.setting.secondStart,
            minuteEnd:
              item.data.setting.minuteEnd === null
                ? ""
                : item.data.setting.minuteEnd,
            secondEnd:
              item.data.setting.secondEnd === null
                ? ""
                : item.data.setting.secondEnd,
            textConfig: item.data.setting.textConfig,
            textEnd: item.data.setting.textEnd,
          };
          this.dataSetingVideo.push(dataInputSeting);
        }
      });
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
    ChapterSettingByContent() {
      this.condition_setting = this.ChapterSettingByContent.is_view;
      if (this.ChapterSettingByContent.slag_id_1 !== undefined) {
        this.slag_type_1 = this.ChapterSettingByContent.slag_id_1.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      if (this.ChapterSettingByContent.slag_id_2 !== undefined) {
        this.slag_type_2 = this.ChapterSettingByContent.slag_id_2.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      if (this.ChapterSettingByContent.slag_id_3 !== undefined) {
        this.slag_type_3 = this.ChapterSettingByContent.slag_id_3.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
      if (this.ChapterSettingByContent.slag_id_4 !== undefined) {
        this.slag_type_4 = this.ChapterSettingByContent.slag_id_4.map(
          (item) => ({
            slag_id: item.slag_id,
            slag_name_en: item.slag_name_en,
          })
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      "ChapterSettingByContent",
      "successSlagCondition",
      "listSlag",
    ]),
  },
  methods: {
    ...mapActions({
      getListPage: "getListPage",
      getALLlistSlag: "getALLlistSlag",
      setSlagBlock: "setSlagBlock",
    }),
    selectSlagType1(value) {
      if (value.length > 0) {
        this.isDisableType2 = false;
      } else {
        this.isDisableType2 = true;
        this.slag_type_2 = [];
        this.slag_type_3 = [];
      }
    },
    selectSlagType2(value) {
      if (value.length > 0) {
        this.isDisableType3 = false;
      } else {
        this.isDisableType3 = true;
        this.slag_type_3 = [];
      }
    },
    // selectSlagType3(value) {
    //   if (value.length > 0) {
    //     this.isDisableType4 = false;
    //   } else {
    //     this.isDisableType4 = true;
    //   }
    // },
    // eslint-disable-next-line no-unused-vars
    onInitialized(editor) {
      // const vueEditor = document.getElementById("vue-editor-js");
      // vueEditor.addEventListener("paste", (e) => {
      //   e.stopPropagation();
      //   let paste = (event.clipboardData || window.clipboardData).getData(
      //     "text"
      //   );
      //   // paste = paste.toUpperCase();
      //   const selection = window.getSelection();
      //   if (!selection.rangeCount) return false;
      //   selection.deleteFromDocument();
      //   paste;
      //   selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      //   e.preventDefault();
      //   e.stopImmediatePropagation();
      //   window.getSelection().removeAllRanges();
      // });
    },
    configEditor() {
      const idShop = this.$route.params.shopId;
      this.config = {
        autofocus: true,
        readOnly: false,
        holder: this.id,
        tools: {
          anyTuneName: {
            class: AlignmentTuneTool,
            config: {
              default: "left",
              blocks: {
                header: "center",
                attachesPDF: "center",
              },
            },
          },
          toggle: {
            class: ToggleBlock,
            tunes: ["anyTuneName"],
            inlineToolbar: true,
          },
          tableOfContents: {
            class: TableOfContents,
            // tunes: ["anyTuneName"],
            inlineToolbar: true,
          },
          header: {
            class: Header,
            config: {
              placeholder: "ヘッダーを入力してください",
              levels: [1, 2, 3, 4, 5, 6],
              defaultLevel: 3,
            },
            inlineToolbar: true,
            tunes: ["anyTuneName"],
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          code: {
            class: CodeTool,
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ["anyTuneName"],
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
            },
          },
          checklist: {
            class: Checklist,
            tunes: ["anyTuneName"],
          },
          Marker: {
            class: MarkerTool,
            shortcut: "CMD+SHIFT+M",
          },
          LineHeight: {
            class: LineHeight,
            shortcut: "CMD+SHIFT+L",
          },
          fontSize: FontSize,
          anyButton: {
            class: anyButton,
            inlineToolbar: false,
            tunes: ["anyTuneName"],
          },
          warning: {
            class: Warning,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+W",
            config: {
              titlePlaceholder: "タイトル",
              messagePlaceholder: "メッセージ",
            },
          },
          raw: RawTool,
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+O",
            config: {
              quotePlaceholder: "引用を入力してください",
              captionPlaceholder: "キャプションを入力してください",
            },
          },
          inlineCode: {
            class: InlineCode,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+M",
          },
          link: {
            class: Hyperlink,
            config: {
              validate: true,
            },
          },
          headerStyle: {
            class: HeaderStyle,
            tunes: ["anyTuneName"],
          },
          boxStylle: {
            class: BoxStyle,
            tunes: ["anyTuneName"],
            // inlineToolbar: true,
          },
          delimiter: Delimiter,
          image: {
            class: ImageTool,
            config: {
              captionPlaceholder: "キャプションを入力してください",
              type: "*/*",
              buttonContent:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z"/></svg> Please 画像を選択してください',
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                async uploadByFile(file) {
                  // eslint-disable-next-line no-undef
                  const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
                  const method = "POST";
                  var formData = new FormData();
                  formData.append("image", file);
                  formData.append("id", idShop);
                  return await Api.adminRequestServer({
                    method,
                    url,
                    data: formData,
                  })
                    .then((response) => {
                      const data = response.data.data; // Get url from response
                      return {
                        success: 1,
                        file: {
                          url: data,
                          // any other image data you want to store, such as width, height, color, extension, etc
                        },
                      };
                    })
                    .catch((err) => {
                      this.$toasted.error(err);
                      console.log("err", err);
                    });
                  // }
                },
              },
            },
            tunes: ["anyTuneName"],
          },
          attachesPDF: {
            class: AttachesPDFTool,
            config: {
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                async uploadByFile(file) {
                  // eslint-disable-next-line no-undef
                  const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
                  const method = "POST";
                  var formData = new FormData();
                  formData.append("image", file);
                  formData.append("id", idShop);
                  return await Api.adminRequestServer({
                    method,
                    url,
                    data: formData,
                  })
                    .then((response) => {
                      const data = response.data.data; // Get url from response
                      return {
                        success: 1,
                        file: {
                          url: data,
                          name: file.name,
                          title: file.name,
                          extension: file.type,
                          // any other image data you want to store, such as width, height, color, extension, etc
                        },
                      };
                    })
                    .catch(() => {
                      this.$toasted.error(
                        "ファイルのアップロードに失敗しました"
                      );
                    });
                },
              },
            },
            tunes: ["anyTuneName"],
          },
          attachesFile: {
            class: AttachesTool,
            config: {
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                async uploadByFile(file) {
                  // eslint-disable-next-line no-undef
                  const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
                  const method = "POST";
                  var formData = new FormData();
                  formData.append("image", file);
                  formData.append("id", idShop);
                  return await Api.adminRequestServer({
                    method,
                    url,
                    data: formData,
                  })
                    .then((response) => {
                      const data = response.data.data; // Get url from response
                      return {
                        success: 1,
                        file: {
                          url: data,
                          name: file.name,
                          size: file.size,
                          title: file.name,
                          extension: file.type,
                          type: file.type,
                          // any other image data you want to store, such as width, height, color, extension, etc
                        },
                      };
                    })
                    .catch(() => {
                      this.$toasted.error(
                        "ファイルのアップロードに失敗しました"
                      );
                    });
                },
              },
            },
          },
          embed: {
            // eslint-disable-next-line no-undef
            class: Embed,
            config: {
              services: {
                youtube: true,
                vimeo: true,
              },
            },
            // tunes: ["anyTuneName"],
          },
          Color: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            inlineToolbar: true,
            config: {
              defaultColor: "#FF1300",
              type: "text",
              customPicker: true,
            },
          },
          ToggleTool: {
            class: ToggleTool,
          },
        },
        /**
         * Internationalzation config
         */
        i18n: {
          /**
           * @type {I18nDictionary}
           */
          messages: {
            /**
             * Other below: translation of different UI components of the editor.js core
             */
            ui: {
              blockTunes: {
                toggler: {
                  "Click to tune": "クリックして調整します。",
                  "or drag to move": "それとも、ドラッグして移動します",
                },
              },
              inlineToolbar: {
                converter: {
                  "Convert to": "変換",
                },
              },
              toolbar: {
                toolbox: {
                  Add: "新規追加",
                },
              },
            },

            /**
             * Section for translation Tool Names: both block and inline tools
             */
            toolNames: {
              Text: "テキスト",
              Heading: "ヘディング",
              List: "一覧",
              Warning: "注意",
              Checklist: "チェックリスト",
              Quote: "引用",
              Code: "コード",
              Delimiter: "デリミタ",
              "Raw HTML": "HTML-生",
              Table: "テーブル",
              Link: "リンク",
              Marker: "マーカー",
              LineHeight: "行間",
              Bold: "ボールド",
              Italic: "イタリック",
              InlineCode: "インラインコード",
              Color: "カラー",
            },

            /**
             * Section for passing translations to the external tools classes
             */
            tools: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
               * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
               */
              warning: {
                // <-- 'Warning' tool will accept this dictionary section
                Title: "タイトル",
                Message: "メッセージ",
              },

              /**
               * Link is the internal Inline Tool
               */
              link: {
                "Add a link": "リンクを追加してください",
              },
              /**
               * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
               */
              stub: {
                "The block can not be displayed correctly.":
                  "ブロックが正しく表示されません",
              },
              image: {
                "With border": "ボーダー",
                "Stretch image": "画像サイズ変更",
                "With background": "背景",
              },
              anyButton: {
                "Button Text": "ボタンに表示するテキスト",
                "Url Twitter": "Twitterリンク先ジャンプボタン",
                "Url Facebook": "Facebookのリンク先ジャンプボタン",
                "Url Pocket": "Pocketのリンク先ジャンプボタン",
                "Url Feedly": "Feedlyのリンク先ジャンプボタン",
                "Link Url": "ボタンの飛び先のURL",
                Set: "設定する",
                "Default Button": "デフォルト",
              },
            },

            /**
             * Section allows to translate Block Tunes
             */
            blockTunes: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
               * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
               *
               * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
               */
              delete: {
                Delete: "削除",
              },
              moveUp: {
                "Move up": "上に移動",
              },
              moveDown: {
                "Move down": "下に移動",
              },
              image: {
                "With border": "ボーダー",
              },
            },
          },
        },
        data: {},
        onReady: () => {
          const editor = this.$refs.editor._data.state.editor;
          new DragDrop(this.$refs.editor._data.state.editor);
          console.log("editor on ready");
          this.showModalBlockRule();
          if (!this.dataEditor) return;
          let data;
          if (typeof this.dataEditor != "object") {
            data = JSON.parse(this.dataEditor);
          } else {
            data = this.dataEditor;
          }
          if (data === null) return;
          data.blocks.map((item) => {
            if (item.slag) {
              let indexOf = data.blocks.indexOf(item);
              const elmSetSlag = editor.blocks.getBlockByIndex(indexOf).holder;
              elmSetSlag.classList.add("block-lock");
            }
          });
        },
        onChange: (args) => {
          args;
          console.log("editor on ready");
          this.$refs.editor._data.state.editor
            .save()
            .then((data) => {
              // Do what you want with the data here
              data;
            })
            .catch((err) => {
              console.log(err);
            });
        },
      };
    },
    submitEditor() {
      const dataSlag = this.getDataSlagBlock;
      const dataSettingVideo = this.dataSetingVideo;
      if (this.$refs.editor) {
        this.$refs.editor._data.state.editor
          .save()
          .then((data) => {
            // Do what you want with the data here
            this.$emit("dataEditor", data);

            data.blocks.map((item) => {
              //
              if (!this.$route.fullPath.includes("fixed-page")) {
                if (item.type === "tableOfContents") {
                  item.data.items = item.data.items.map(
                    (itemTOC, index) =>
                      (itemTOC = { text: itemTOC, href: data.blocks[index].id })
                  );
                }
              }
              if (dataSlag.length > 0) {
                dataSlag.map((itemSlag) => {
                  if (item.id === itemSlag.id) {
                    item.slag = itemSlag;
                  }
                });
              }
              if (dataSettingVideo.length > 0) {
                dataSettingVideo.map((itemSetting) => {
                  if (item.id === itemSetting.idBlock) {
                    item.data.setting = itemSetting;
                  }
                });
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showModalBlockRule() {
      const toolbarActionBtn = document.getElementsByClassName(
        "ce-toolbar__settings-btn"
      );
      toolbarActionBtn[0].addEventListener("click", () => {
        const editor = this.$refs.editor._data.state.editor;
        const currentBlockId = editor.blocks.getCurrentBlockIndex();
        const currentBlockData = editor.blocks.getBlockByIndex(currentBlockId);
        this.blockItemId = currentBlockData.id;
        currentBlockData.holder.setAttribute("id", currentBlockData.id);
        const btnModalSettingVideo = document.getElementById(
          "buttonModalSettingVideo"
        );
        const btnCallShowModal = document.getElementById("buttonModalSetRule");
        if (btnCallShowModal != undefined) {
          btnCallShowModal.addEventListener("click", () => {
            this.$refs["modalBlockRule"].show();
            if (this.getDataSlagBlock.length < 1) return;
            this.slag_type_1 = [];
            this.slag_type_3 = [];
            this.slag_type_2 = [];
            this.slag_type_4 = [];
            const dataFilterSlag = this.getDataSlagBlock.filter(
              (item) => item.id === this.blockItemId
            )[0];
            if (dataFilterSlag != undefined) {
              this.listSlagOptions.map((itemSlagOption) => {
                for (const variable in dataFilterSlag.slag_id_1) {
                  if (
                    itemSlagOption.slag_id ===
                    dataFilterSlag.slag_id_1[variable]
                  ) {
                    this.slag_type_1.push(itemSlagOption);
                    this.isDisableType2 = false;
                  }
                }
                for (const variable in dataFilterSlag.slag_id_2) {
                  if (
                    itemSlagOption.slag_id ===
                    dataFilterSlag.slag_id_2[variable]
                  ) {
                    this.slag_type_2.push(itemSlagOption);
                    this.isDisableType3 = false;
                  }
                }
                for (const variable in dataFilterSlag.slag_id_3) {
                  if (
                    itemSlagOption.slag_id ===
                    dataFilterSlag.slag_id_3[variable]
                  ) {
                    this.slag_type_3.push(itemSlagOption);
                  }
                }
                for (const variable in dataFilterSlag.slag_id_4) {
                  if (
                    itemSlagOption.slag_id ===
                    dataFilterSlag.slag_id_4[variable]
                  ) {
                    this.slag_type_4.push(itemSlagOption);
                  }
                }
              });
            }
          });
        }
        if (btnModalSettingVideo != undefined) {
          btnModalSettingVideo.addEventListener("click", () => {
            const urlVideo = currentBlockData.holder.textContent;
            let regExpYtu =
              /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
            var matchYutu = urlVideo.match(regExpYtu);
            if (matchYutu != null) {
              this.idVideoEmbed = matchYutu[1];
            } else {
              const regExpVimeo =
                /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
              var matchVimeo = urlVideo.match(regExpVimeo);

              if (matchVimeo) {
                this.videoID = matchVimeo[5];
                this.idVideoEmbed = matchVimeo[5];
              } else {
                this.idVideoEmbed = "";
                console.log("url not found");
              }
            }
            this.minuteStart = "";
            this.secondStart = "";
            this.minuteEnd = "";
            this.secondEnd = "";
            this.textConfig = "";
            this.textEnd = "";
            this.dataSetingVideo.filter((item) => {
              if (item.idBlock === this.blockItemId) {
                this.minuteStart = item.minuteStart;
                this.secondStart = item.secondStart;
                this.minuteEnd = item.minuteEnd;
                this.secondEnd = item.secondEnd;
                this.textConfig = item.textConfig;
                this.textEnd = item.textEnd;
              }
            });
            this.$refs["modalSettingVideo"].show();
          });
        }
      });
    },
    getSlagBlock() {
      const elmBlock = document.getElementById(this.blockItemId);
      let slagType1 = this.slag_type_1;
      let slagType2 = this.slag_type_2;
      let slagType3 = this.slag_type_3;
      let slagType4 = this.slag_type_4;
      const dataFilterSlag = this.getDataSlagBlock.filter(
        (item) => item.id === this.blockItemId
      )[0];
      const indexOfItemSlag = this.getDataSlagBlock.indexOf(dataFilterSlag);
      if (slagType1.length < 1 && slagType4.length < 1) {
        elmBlock.classList.remove("block-lock");
        if (slagType1.length < 1 || slagType4.length < 1) {
          this.getDataSlagBlock.splice(indexOfItemSlag, 1);
        }
      } else {
        if (dataFilterSlag != undefined) {
          if (slagType1.length < 1 || slagType4.length < 1) {
            this.getDataSlagBlock.splice(indexOfItemSlag, 1);
          } else {
            const dataItemSlag = this.getDataSlagBlock[indexOfItemSlag];
            dataItemSlag.slag_id_1 = [];
            dataItemSlag.slag_id_2 = [];
            dataItemSlag.slag_id_3 = [];
            dataItemSlag.slag_id_4 = [];
            for (const variable in slagType1) {
              dataItemSlag.slag_id_1.push(slagType1[variable].slag_id);
            }
            for (const variable in slagType2) {
              dataItemSlag.slag_id_2.push(slagType2[variable].slag_id);
            }
            for (const variable in slagType3) {
              dataItemSlag.slag_id_3.push(slagType3[variable].slag_id);
            }
            for (const variable in slagType4) {
              dataItemSlag.slag_id_4.push(slagType4[variable].slag_id);
            }
          }
          elmBlock.classList.add("block-lock");
        } else {
          if (this.slag_type_1.length > 0 || this.slag_type_4.length > 0) {
            elmBlock.classList.add("block-lock");
          } else {
            elmBlock.classList.remove("block-lock");
          }

          slagType1 = slagType1.map((item) => {
            return (item = item.slag_id);
          });

          slagType2 = slagType2.map((item) => {
            return (item = item.slag_id);
          });

          slagType3 = slagType3.map((item) => {
            return (item = item.slag_id);
          });

          slagType4 = slagType4.map((item) => {
            return (item = item.slag_id);
          });

          const dataPush = {
            id: this.blockItemId,
            slag_id_1: slagType1,
            slag_id_2: slagType2,
            slag_id_3: slagType3,
            slag_id_4: slagType4,
          };
          this.getDataSlagBlock.push(dataPush);
        }
      }
      this.$refs["modalBlockRule"].hide();
    },
    closeModalSetSlag() {
      this.$refs["modalBlockRule"].hide();
    },
    onChangeMinuteStart() {
      this.minuteStart = this.minuteStart.replace(/[^0-9.]/g, "");
      // let valueMinuteStart = Number(
      //   this.minuteStart === "" ? "0" : this.minuteStart
      // );
      // let valueMinuteEnd = Number(this.minuteEnd === "" ? "0" : this.minuteEnd);
      // if (this.minuteStart === "" || this.minuteStart.length < 2) {
      //   this.stateMinuteStart = false;
      //   this.disabledSaveSetiingVideo = true;
      // } else {
      //   console.log(this.minuteEnd);
      //   if (this.minuteEnd === "" || this.minuteEnd.length < 2) {
      //     this.stateMinuteEnd = false;
      //     this.disabledSaveSetiingVideo = true;
      //   } else {
      //     if (valueMinuteStart >= valueMinuteEnd) {
      //       this.stateMinuteStart = false;
      //       this.stateMinuteEnd = false;
      //       this.disabledSaveSetiingVideo = true;
      //     } else {
      //       this.disabledSaveSetiingVideo = false;
      //       this.stateMinuteStart = null;
      //     }
      //   }
      // }
    },
    onChangeSecondStart() {
      // if (this.secondStart.length < 2) {
      //   this.stateSecondStart = false;
      //   this.disabledSaveSetiingVideo = true;
      // } else {
      //   this.stateSecondStart = null;
      //   this.disabledSaveSetiingVideo = false;
      // }
    },
    onChangeMinuteEnd() {
      this.minuteEnd = this.minuteEnd.replace(/[^0-9.]/g, "");
      // const valueMinuteStart = parseInt(
      //   this.minuteStart === "" ? "0" : this.minuteStart
      // );
      // const valueMinuteEnd = parseInt(
      //   this.minuteEnd === "" ? "0" : this.minuteEnd
      // );
      // if (
      //   this.minuteStart === "" ||
      //   this.minuteStart.length < 2 ||
      //   this.minuteEnd === "" ||
      //   this.minuteEnd.length < 2
      // ) {
      //   this.stateMinuteEnd = false;
      //   this.disabledSaveSetiingVideo = true;
      // } else {
      //   // this.stateMinuteEnd = true;
      //   // this.disabledSaveSetiingVideo = false;
      //   if (valueMinuteStart >= valueMinuteEnd) {
      //     this.stateMinuteEnd = false;
      //     this.disabledSaveSetiingVideo = true;
      //   } else {
      //     this.stateMinuteEnd = null;
      //     this.stateMinuteStart = null;
      //     this.disabledSaveSetiingVideo = false;
      //   }
      // }
    },
    onChangeSecondEnd() {
      // if (this.secondEnd.length < 2) {
      //   this.stateSecondEnd = false;
      //   this.disabledSaveSetiingVideo = true;
      // } else {
      //   this.stateSecondEnd = null;
      //   this.disabledSaveSetiingVideo = false;
      // }
    },
    createModalSettingvideo() {
      const dataBlockSettingVideo = this.dataSetingVideo.filter(
        (item) => item.idBlock === this.blockItemId
      );
      const indexOfItemSetting = this.dataSetingVideo.indexOf(
        dataBlockSettingVideo[0]
      );
      if (dataBlockSettingVideo.length > 0) {
        this.dataSetingVideo.filter((item) => {
          if (item.idBlock === this.blockItemId) {
            const dataItemSetting = this.dataSetingVideo[indexOfItemSetting];

            dataItemSetting.minuteStart =
              this.minuteStart === "" ? 0 : this.minuteStart;
            dataItemSetting.secondStart =
              this.secondStart === "" ? 0 : this.secondStart;
            dataItemSetting.minuteEnd =
              this.minuteEnd === "" ? 0 : this.minuteEnd;
            dataItemSetting.secondEnd =
              this.secondEnd === "" ? 0 : this.secondEnd;
            dataItemSetting.textConfig = this.textConfig;
            dataItemSetting.textEnd = this.textEnd;
          }
        });
      } else {
        let dataInputSeting = {
          idBlock: this.blockItemId,
          minuteStart: this.minuteStart === "" ? 0 : this.minuteStart,
          secondStart: this.secondStart === "" ? 0 : this.secondStart,
          minuteEnd: this.minuteEnd === "" ? 0 : this.minuteEnd,
          secondEnd: this.secondEnd === "" ? 0 : this.secondEnd,
          textConfig: this.textConfig,
          textEnd: this.textEnd,
        };
        this.dataSetingVideo.push(dataInputSeting);
      }
      this.$refs["modalSettingVideo"].hide();
    },
    closeModalSettingvideo() {
      this.$refs["modalSettingVideo"].hide();
    },
  },
};
</script>

<style lang="scss">
.ce-block__content,
.ce-toolbar__content {
  width: 100%;
  max-width: calc(100% - 4.2rem);
}
.ce-tune-alignment--right {
  .ce-paragraph {
    text-align: right;
  }
  .cdx-checklist {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .image-tool__image-picture {
    margin: 0 0 0 auto;
  }
  .cdx-attaches {
    object {
      margin: 0 0 0 auto;
    }
  }
}
.ce-tune-alignment--center {
  .ce-paragraph {
    text-align: center;
  }
  .cdx-checklist {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .image-tool__image-picture {
    margin: 0 auto;
  }
  .cdx-attaches {
    object {
      margin: 0 auto;
    }
  }
}
.ce-tune-alignment--left {
  .ce-paragraph {
    text-align: left;
  }
  .cdx-checklist {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .image-tool__image-picture {
    margin: 0 auto 0 0;
  }
  .cdx-attaches {
    object {
      margin: 0 auto 0 0;
    }
  }
}
</style>
